<template>
  <div class="">
    <h2>アンケート</h2>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="35"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-bule.png?alt=media&token=a579655b-d85a-437a-93ce-034100fcb34e"
              variant="light-primary"
            />
          </div>
          <div class="">
            事前にアンケートにご回答頂くと、より早くお仕事をご紹介できます。遠慮せず、ご希望をお聞かせください！
          </div>
        </div>
      </div>
    </b-alert>
    <b-card
      v-if="inputForm"
      title=""
    >
      <b-row>
        <b-col
          cols="4"
          class="text-right"
        >
          現在の状況
        </b-col>
        <b-col>
          <div v-if="inputForm.workingStatus">
            {{ inputForm.workingStatus.labelName || '' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          転職時期
        </b-col>
        <b-col>
          <div v-if="inputForm.deadline">
            {{ inputForm.deadline.labelName || '' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          希望勤務地
        </b-col>
        <b-col>
          <div class="">
            {{ inputForm.place || '' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          希望職種
        </b-col>
        <b-col>
          <div
            v-for="(item, index) in inputForm.jobtype"
            :key="index"
          >
            {{ item }}
          </div>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col
          cols="4"
          class="text-right"
        >
          <!-- title -->
        </b-col>
        <b-col>
          <div class="">
            {{ inputForm.jobtype_other || '' }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="4"
          class="text-right"
        >
          希望年収
        </b-col>
        <b-col>
          <div
            v-for="(item, index) in inputForm.incom"
            :key="index"
          >
            <div class="">
              {{ item }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <!-- submit and reset -->
        <b-col class="text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="move('hearing-edit')"
          >
            編集する
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-alert
      variant="secondary"
      show
    >
      <div class="alert-body mt-1">
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              size="35"
              src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgril-red.png?alt=media&token=4033ef9a-cd19-48e6-baf6-85065c0a3c96"
              variant="light-primary"
            />
          </div>
          <div class="">
            転職前は不安がいっぱいですが、転コレエージェントはあなたの味方です！どんなお仕事が良いのか、ご希望をたくさん聞かせて下さいね！
          </div>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BButton, BCard, BAlert, BAvatar,
} from 'bootstrap-vue'
/* eslint-disable */
import menulist from '@/components/conf/アンケート.json'
/* eslint-enable */
import getSingleData from '@/firestore/data/get'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BAlert,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      menulist,
    }
  },
  async mounted() {
    const data = {
      collection: 'hearing',
    }
    const response = await getSingleData(data)
    if (response.status === 'success') {
      this.inputForm = response.data
    } else {
      window.console.log('error', response.msg)
    }
  },
  methods: {
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
