<template>
  <div class="space_">
    <Confirm />
  </div>
</template>

<script>
// import { BRow, BCol } from 'bootstrap-vue'
import Confirm from '@/components/jobSeeker/hearing/Confirm.vue'

export default {
  components: {
    Confirm,
  },
}
</script>

<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
